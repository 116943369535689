<template>
  <ClientOnly>
    <Popover class="footer-newsletter-wrapper">
      <div class="footer-newsletter-content-wrapper">
        <h3 class="footer-newsletter-head">
          {{$t('newsletter.subscribe-newsletter')}}
        </h3>
        <p class="footer-newsletter-desc">
          {{$t('newsletter.for-news-and-opportunities')}}
        </p>
      </div>

      <div class="footer-newsletter-input-wrapper">
        <input
            class="footer-newsletter-input"
            type="email"
            v-model="email"
            id="marketingEmail"
            name="marketingEmail"
            :placeholder="$t('newsletter.enter-email')"
        />
        <PopoverButton class="footer-newsletter-btn">
          <ArrowRightIcon class="footer-newsletter-btn-icon"/>
        </PopoverButton>
      </div>

      <PopoverPanel class="footer-newsletter-popover">
        <div class="footer-newsletter-popover-content">
          <FormCheckbox
              type="checkbox"
              v-model="isConfirm"
              name="allowAgreement"
              required
              id="gdpr-email-confirm"
          >
            {{$t('allow-notification')}}
          </FormCheckbox>
          <FormButton color="primary" class="w-full" @click="subscribeEmailList" :loading="loading">
            {{$t('newsletter.subscribe')}}
          </FormButton>
        </div>
      </PopoverPanel>
    </Popover>
  </ClientOnly>
</template>

<script setup>
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import {ArrowRightIcon} from '@heroicons/vue/20/solid';
const {$showToast} = useNuxtApp();
const config = useRuntimeConfig()

const email = ref();
const isConfirm = ref(false);
const loading = ref(false);
const merchantTitle = getLocaleString(config.public?.site_title);

const subscribeEmailList = async () => {
  loading.value = true;
  const {data} = await useLazyFetch('/api/newsletter/subscribe', {
    method : 'post',
    body : {
      email : email.value,
      isConfirm : isConfirm.value
    }
  });

  $showToast(data.value.message, data.value.status);

  if (data.value?.status === 'success') {
    email.value = null;
    isConfirm.value = false;
  }

  loading.value = false;
}
</script>

<style lang="scss">

.footer-newsletter-wrapper {
  @apply relative w-full lg:w-auto lg:min-w-[350px];
}

.footer-newsletter-head {
  @apply text-base font-bold text-primary-400 text-left tracking-wider uppercase;
}

.footer-newsletter-desc {
  @apply text-base;
}

.footer-newsletter-content-wrapper {
  @apply mt-0;
}

.footer-newsletter-input-wrapper {
  @apply flex items-center justify-center border border-gray-200;
}

.footer-newsletter-input {
  @apply text-base p-1 w-full border-0 focus:outline-none;
}

.footer-newsletter-btn {
  @apply focus:outline-none px-2;
}

.footer-newsletter-btn-icon {
  @apply h-4 w-4;
}

.footer-newsletter-popover {
  @apply absolute z-100 right-0 w-full max-w-[350px];
}

.footer-newsletter-popover-content {
  @apply bg-white overflow-hidden rounded-b-lg shadow-lg ring-1 ring-black ring-opacity-5 p-4 space-y-6;
}

</style>